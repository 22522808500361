<template>
  <div>
    <div>
      <validation-observer ref="noteObserverRule">
        <b-modal
          :id="id"
          v-model="modalStatus"
          ok-only
          ok-title="Accept"
          centered
          size="lg"
          title="Large Modal"
          no-close-on-backdrop
          content-class="noteditorpopup"
        >
          <template #modal-footer>
            <div
              class="d-flex justify-content-between align-items-center w-100"
            >
              <div class="notDropdown"></div>
              <div>
                <b-button
                  variant="primary"
                  size="sm"
                  class="btnClose"
                  :disabled="loader"
                  @click="validationForm()"
                >
                  Save
                </b-button>
              </div>
            </div>
          </template>
          <div class="modalpopupInfo">
            <validation-provider
              #default="{ errors }"
              name="Note title"
              rules="required|max:100"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Title"
                name="name"
                class="modalnoteTitle"
                @blur="tabInMyQuillEditor"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required|validLink|validateNoImage"
            >
              <quill-editor
                ref="myQuillEditor"
                v-model="content"
                :options="editorOption"
                class="editorDesign"
              >
                <div id="toolbar" slot="toolbar" class="order-2 mt-1">
                  <small class="text-danger mb-1 mt-0">{{ errors[0] }}</small>
                  <button class="ql-bold">Bold</button>
                  <button
                    class="ql-link"
                  ></button>
                </div>
              </quill-editor>
            </validation-provider>
          </div>
        </b-modal>
      </validation-observer>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor, Quill } from "vue-quill-editor"
import QuillMention from "quill-mention"
import "quill-mention/dist/quill.mention.css"
import { eventBus } from "@/main"
import moment from "moment"
import { ValidationObserver, ValidationProvider, extend } from "vee-validate"

Quill.register("modules/mention", QuillMention)

extend("validLink", {
  validate(value) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(value, "text/html")

    const invalidLink = []
    const linkElements = doc.querySelectorAll("a")

    if (linkElements && linkElements.length) {
      linkElements.forEach((linkElement, index) => {

        const href = linkElement.getAttribute("href")
        if (!/^https?:\/\//.test(href)) {
          invalidLink.push(`Invalid link at position ${index + 1}`)
        }

      })
    }
    return !invalidLink.length
  },
  message: "Please enter valid link",
})

export default {
  name: "Noteseditors",
  components: {
    quillEditor,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    // data: {
    //   type: Object,
    //   default: null,
    // },
    id: {
      type: String,
      default: null,
    },
    tags: {
      required: true,
      type: Array,
    },
  },

  data: () => ({
    modalStatus: false,

    delta: undefined,
    content: null,
    name: null,
    data: null,
    loader: false,
    errors: [],
  }),
  computed: {
    editorOption() {
      const {tags} = this
      const editorOption = {
        modules: {
          toolbar: "#toolbar",
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["#"],
            source: (searchTerm, renderList) => {
              const suggestions = tags
              const matches = suggestions.filter(suggestion =>
                suggestion.value
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
              renderList(matches, searchTerm)
            },
          },
          keyboard: {
            bindings: {
              tab: false, // Disable the tab key
            },
          },
        },
      }
      return editorOption
    },
  },
  watch: {
    modalStatus(val) {
      if (!val) {
        // this.addNotes();
        this.content = null
        this.name = null
        this.data = null
      }
    },
  },
  mounted() {
    eventBus.$on("createNote", data => {
      if (data) {
        this.content = null
        this.name = null
        this.data = null
      }
    })
    eventBus.$on("updateNote", data => {
      if (data) {
        this.data = data
        this.name = data ? data.title : null
        this.content = data ? data.description : null
      }
    })
  },
  methods: {
    customButtonClick() {
      alert("Button clicked!")
    },

    validationForm() {
      this.$refs.noteObserverRule.validate().then(success => {
        if (success) {
          this.addNotes()
        }
      })
    },

    clearData() {
      this.content = null
      this.name = null
      this.data = null
    },
    /* member add in project */
    async addNotes() {
      if (!this.content) {
        this.content = null
        return
      }
      this.loader = true
      const input = {
        note_id: this.data && this.data.id ? this.data.id : null,
        title: this.name,
        project_id: this.$route.params.id3,
        description: this.content,
        date: moment().format("YYYY-MM-DD"),
      }
      const response = await this.getHTTPPostResponse(
        "project/note/create-update",
        input,
        true
      )

      if (response && response.status == 200) {
        eventBus.$emit("noteList", true)
        this.$bvModal.hide(this.data && this.data.id ? this.data.id : this.id)
        this.clearData()
      }
      this.loader = false
    },

    /** tab  */
    tabInMyQuillEditor() {
      this.$refs.myQuillEditor.quill.focus()
    },
    // callNewMethod() {
    //   const sidebarBody = document.querySelector(".ql-tooltip");
    //   sidebarBody.classList.remove("ql-hidden");
    //   const customButton = document.querySelector(".ql-action");

    //   // customButton.addEventListener('click', () => {

    //   // });
    // },
    // isValidLink(link) {
    //   // Implement your link validation logic here
    //   // You can use regular expressions or any other method to validate links
    //   // For example, checking if the link starts with "http://" or "https://"
    //   return /^https?:\/\//.test(link);
    // },
  },
}
</script>
<style>
.ql-mention-list-container {
  width: 270px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ql-mention-list-item {
  cursor: pointer;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
}

.ql-mention-list-item.selected {
  background-color: #d3e1eb;
  text-decoration: none;
}

.mention {
  height: 24px;
  width: 65px;
  border-radius: 6px;
  background-color: #d3e1eb;
  padding: 3px 0;
}

.mention > span {
  margin: 0 3px;
}
</style>
